/** IMPORTS **/
@import 'functions', 'mixins', '_custom_bootstrap_variables';
@import '_base';

/*  RESET & SETUP BASIC HTML ELEMENTS */
body{
    font-family: $body-font;
    font-weight: normal;
}

h1, h2, h3{
    font-family: $title-font;
    font-weight: bold;
    color: $abbey;
}
h1 {
    font-size: 2rem; /* mobile smaller font-size */
    @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
    }
}

/* General use */
.bold {
    font-weight: bold;
}

